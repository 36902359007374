<template>
    <div class="centered">
      <h1><i class="not-found-icon nc-icon nc-puzzle-10"></i>404</h1>
      <p>The page you requested could not be found.</p>
    </div>
</template>

<script>
export default {
  
};
</script>
<style>

</style>
