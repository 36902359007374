<template>
  <section class="speciality-area">
    <div style="display: flex;justify-content: space-around;">
      <div style="width: 200px;" class="left-side-container mr-2">
        <p class="blink mb-5"> <a href="http://editorial.fdrpjournals.org/login?journal=1" style="color: #fff;">Submit Research Paper</a> </p>
        <div class="shadow-effect mb-5">
          <h6>Downloads :</h6>
          <p style="cursor: pointer;"
            @click="getManuScript('https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/1/manuscripttemplate/IJIRE-Manuscript-Template-new.docx')">
            Manuscript Template
          </p>
          <p style="cursor: pointer;"
            @click="getCopyRightForm('https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/1/copyrightform/IJIRE_Registration+%26Copyright-Form.pdf')">
            Copyright Form
          </p>
        </div>
        <div class="shadow-effect mb-5">
          <img src="../../../assets/Images/isn.jpg" height="90" width="200" />
        </div>
        <div class="shadow-effect mb-5">
          <img src="../../../assets/Images/cc.jpg" height="90" width="180" />
          <p>All research Article published on this website are licensed under Creative Commons Attribution-ShareAlike
            4.0
            International License, and all rights belong to their respective authors/researchers.
          </p>
        </div>
        <div class="image-container mb-5">
          <div class="image-wrapper" v-for="(item, index) in images" :key="index">
            <img :src="item.img" height="50" width="80" />
          </div>
        </div>
      </div>
    <div class="container" style="max-width: 1000px !important; margin: 0">
      <div class="row">
        <div class="row">
          <div class="col-lg-12 col-md-12 mt-2">
            <div class="archive-data-container" v-if="!listVisible && !paperPage"> 
              <div class="" v-for="year in years" :key="year">
                <h6>{{ year }}</h6>
                <div class="row"  
                v-for="(data, index) in archives[year]" :key="data.index">
                  <div class="col-lg-3 databox m-1 issueButton"   v-for="issue in data.slice().sort()"
                    :key="issue.index"
                    @click="viewIssueData(year, index, issue)"> 
                    <p>Volume {{ index }} Issue {{ issue }}</p>
                  </div> 
                </div>
              </div>
              </div>
            </div>
            
            <div class="paperlist-container" v-if="listVisible && !paperPage">
              <div class="row paperBox paperHeading mb-4 p-2 ">
                <h1 >{{ cYear }} | Volume {{ cVolume }} - Issue {{ cIssue }}</h1>
              </div>
              <div
                class="row paperBox mb-3"
                v-for="paper in papersList"
                :key="paper.paper_uniqueid"
              >
                <div class="col-9 p-3">
                  <h2>{{ paper.paper_title }}</h2>
                  <p><b>Author:</b> {{ paper.paper_author }}</p>
                  <p><b>Article Type:</b> {{ paper.paper_articletype }}</p>
                  <p><b>Pages:</b> {{ paper.paper_pages }}</p>
                </div>
                <div class="col-3 p-3">
                  <b-button
                    class="col-8 mb-2"
                    variant="outline-primary"
                    size="sm"
                    @click="viewPaperDetails(paper.paper_id)"
                    >Paper Details</b-button
                  >
                  <b-button
                    class="col-8 mb-2"
                    variant="outline-primary"
                    size="sm"
                    @click="downloadPDF(paper.paper_url)"
                    >Download PDF</b-button
                  >
                  <b-button
                    class="col-8"
                    variant="outline-primary"
                    size="sm"
                    @click="googleSearch('https://www.google.com/search?q='+paper.paper_title)"
                    >Google</b-button
                  >
                </div>
              </div>
            </div>
            <div class="paperdetails-container" v-if="paperPage">
              <div class="row  paperBox mb-4 p-3">
                <div class="header-container row justify-content-between rowSize">
                  <h1 class="col-10 pr-2">{{ paperDetails.paper_title }}</h1>
                  <div class="col-2" >
                    <b-button 
                      variant="outline-primary"
                      
                      @click="downloadPDF(paperDetails.paper_url)"
                      >Download PDF</b-button
                    >
                  </div>
                </div>
                <div class="details-container mt-4 p-3">
                
                    <p class="pb-3"><b>Author(s) : </b>{{ paperDetails.paper_author }}</p>
                
                    <p class="pb-3"><b>Submission-Year,Month : </b>{{ paperDetails.year }}, {{ paperDetails.month }}</p>
                
                    <p class="pb-3"><b>Pages : </b>{{ paperDetails.paper_pages }}</p>
                  
                    <p class="pb-3"><b>Article Type : </b>{{ paperDetails.paper_articletype }}</p>
                  
                    <p class="pb-3"><b>DOI : </b>{{ paperDetails.paper_doi }}</p>
                
                </div>
              </div> 
              <div class="row p-3 paperBox">
                <h4 class="mb-2">Abstract</h4>
                <p class="abstract-details">
                  {{ paperDetails.paper_abstract }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 200px;" class="right-side-container ml-2">
        <div class="mb-5">
        <router-link to="/editorsandreviewers">
          <p class="blink">Join As A reviewer</p>
        </router-link>
      </div>
        <div  class="shadow-effect mb-5">
          <img src="../../../assets/Images/tur.jpg" height="90" width="180" />
          <p>Plagiarism is checked by the leading plagiarism checker</p>
        </div>
        <div  class="shadow-effect mb-5">
          <img src="../../../assets/Images/doi.jpg" height="90" width="180" />
          <p>CrossRef DOI is assigned to research Article published in our journal.
            IJIRE DOI prefix is10.59256/ijire
          </p>
        </div>
        <div class="shadow-effect mb-5">
          <h4>For Authors</h4>
          <router-link to="topics"><p>Topics</p></router-link>
          <router-link to="call-for-papers"><p>Call For Papers</p></router-link>
          <router-link to="instruction-for-author"><p>Instruction For Authors</p></router-link>
          <p ><a href="http://editorial.fdrpjournals.org/login?journal=1" style="color:rgb(100, 117, 137)">Manuscript Submission</a></p>
          <router-link to="guidance-for-ems"><p>Guidance For EMS</p></router-link>
          <router-link to="article-processing-charges"><p>Article Processing Charges</p></router-link>
          <router-link to="downloads"><p>Downloads</p></router-link>
          <router-link to="paper-status"><p>Paper Status</p></router-link>
        </div>
        <div class="shadow-effect">
          <h4>Policies</h4>
          <router-link to="ethics"><p>Ethics And Policies</p></router-link>
          <router-link to="peer-review-policy"><p>Peer Review Policy</p></router-link>
          <router-link to="publication-ethics-policy"><p>Publication Ethics Policy</p></router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<!-- <script>
export default {
  data: function () {
    return {
      archives: {},
      years: [],
      papersList: [],
      listVisible: false,
      paperPage: false,
      cYear: "",
      cVolume: "",
      cIssue: "",
      paperDetails: {},
      ManuScriptUrl:'',
      CopyRightFormUrl:'',
      images: [
        {
          img: require('../../../assets/Images/one.jpeg')
        },
        {
          img: require('../../../assets/Images/two.png')
        },
        {
          img: require('../../../assets/Images/three.png')

        },
        {
          img: require('../../../assets/Images/four.png')

        },
        {
          img: require('../../../assets/Images/five.png')

        },
        {
          img: require('../../../assets/Images/six.jpg')

        },
        {
          img: require('../../../assets/Images/seven.jpg')

        },
        {
          img: require('../../../assets/Images/eight.jpeg')

        },
        {
          img: require('../../../assets/Images/nine.jpeg')

        },
        {
          img: require('../../../assets/Images/ten.png')

        },
        {
          img: require('../../../assets/Images/eleven.png')

        },
        {
          img: require('../../../assets/Images/twele.jpg')

        },
        {
          img: require('../../../assets/Images/thirteen.jpg')

        },
        {
          img: require('../../../assets/Images/nineteen.png')

        },
        {
          img: require('../../../assets/Images/fourteen.png')

        },
        {
          img: require('../../../assets/Images/fifteen.jpg')

        },
        {
          img: require('../../../assets/Images/sixteen.jpg')

        },
        {
          img: require('../../../assets/Images/seventeen.jpeg')

        },
        {
          img: require('../../../assets/Images/eighteen.jpg')

        },
      ],
    };
  },
  methods: {
    getArchivesData: function () {
      this.api.getDataModule("archives/1/getarchives", "journals/v1/").then(
        (res) => {
          console.log(res.data);
          this.archives = res.data.archives;
          this.sortData();
        },
        (err) => {
          console.log(err);
        }
      );
    },
    getManuScript: function (getUrl) {
      window.open(getUrl, "_blank");
    },
    getCopyRightForm: function (getForm) {
      window.open(getForm, "_blank");
    },
    getPapersList: function (year, volume, issue) {
      this.api
        .getDataModulePagination(
          "archives/1/getpaperslist",
          "journals/v1/",
          "year=" + year + "&volume=" + volume + "&issue=" + issue
        )
        .then(
          (res) => {
            this.papersList = res.data.papersList;
          },
          (err) => {
            console.log(err);
          }
        );
    },
    getPaperDetails: function (paperid) {
      this.api
        .getDataModulePagination(
          "archives/1/getpaperdetails",
          "journals/v1/",
          "paperid=" + paperid
        )
        .then(
          (res) => {
            this.paperDetails = res.data.paperdetails;
          },
          (err) => {
            console.log(err);
          }
        );
    },
    sortedIssues: function (val) {
      return val.sort().reverse();
    },
    sortData: function () {
      this.years = Object.keys(this.archives).sort().reverse();
    },
    viewIssueData: function (year, volume, issue) {
      this.getPapersList(year, volume, issue);
      this.cYear = year;
      this.cVolume = volume;
      this.cIssue = issue;
      this.listVisible = true;
      this.$router.push({
        path: "archives",
        query: { year: year, volume: volume, issue: issue },
      });
    },
    viewPaperDetails: function (paperid) {
      this.getPaperDetails(paperid);
      this.paperPage = true;
      this.$router.push({ path: "archives", query: { paperid: paperid } });
    },
    downloadPDF: function (paperURL) {
      window.open(paperURL, "_blank");
    },
    googleSearch(url) {
      window.open(url, "_blank");
    },
  },
  watch: {
    $route: function () {
      console.log("here");
      if (
        !this.paperPage &&
        this.listVisible &&
        this.cYear == this.$route.query.year &&
        this.cVolume == this.$route.query.volume &&
        this.cIssue == this.$route.query.issue
      ) {
        this.listVisible = true;
      } else {
        this.listVisible = false;
      }

      if ("paperid" in this.$route.query) {
        this.viewPaperDetails(this.$route.query.paperid);
      } else {
        this.paperPage = false;
        this.getArchivesData();
      }
    },
  },
  mounted: function () {
    if ("paperid" in this.$route.query) {
      this.viewPaperDetails(this.$route.query.paperid);
    } else {
      this.listVisible = false;
      this.paperPage = false;
      this.getArchivesData();
      console.log("here");
    }
  },
};
</script> -->


<!-- <script>
export default {
  data: function () {
    return {
      archives: {},
      years: [],
      papersList: [],
      listVisible: false,
      paperPage: false,
      cYear: "",
      cVolume: "",
      cIssue: "",
      paperDetails: {},
      ManuScriptUrl: '',
      CopyRightFormUrl: '',
      images: [
        { img: require('../../../assets/Images/one.jpeg') },
        { img: require('../../../assets/Images/two.png') },
        { img: require('../../../assets/Images/three.png') },
        { img: require('../../../assets/Images/four.png') },
        { img: require('../../../assets/Images/five.png') },
        { img: require('../../../assets/Images/six.jpg') },
        { img: require('../../../assets/Images/seven.jpg') },
        { img: require('../../../assets/Images/eight.jpeg') },
        { img: require('../../../assets/Images/nine.jpeg') },
        { img: require('../../../assets/Images/ten.png') },
        { img: require('../../../assets/Images/eleven.png') },
        { img: require('../../../assets/Images/twele.jpg') },
        { img: require('../../../assets/Images/thirteen.jpg') },
        { img: require('../../../assets/Images/nineteen.png') },
        { img: require('../../../assets/Images/fourteen.png') },
        { img: require('../../../assets/Images/fifteen.jpg') },
        { img: require('../../../assets/Images/sixteen.jpg') },
        { img: require('../../../assets/Images/seventeen.jpeg') },
        { img: require('../../../assets/Images/eighteen.jpg') },
      ],
    };
  },
  methods: {
    getArchivesData: function () {
      this.api.getDataModule("archives/1/getarchives", "journals/v1/").then(
        (res) => {
          console.log(res.data);
          this.archives = res.data.archives;
          this.sortData();
        },
        (err) => {
          console.log(err);
        }
      );
    },
    getManuScript: function (getUrl) {
      window.open(getUrl, "_blank");
    },
    getCopyRightForm: function (getForm) {
      window.open(getForm, "_blank");
    },
    getPapersList: function (year, volume, issue) {
      this.api
        .getDataModulePagination(
          "archives/1/getpaperslist",
          "journals/v1/",
          "year=" + year + "&volume=" + volume + "&issue=" + issue
        )
        .then(
          (res) => {
            this.papersList = res.data.papersList;
          },
          (err) => {
            console.log(err);
          }
        );
    },
    getPaperDetails: function (paperid) {
      this.api
        .getDataModulePagination(
          "archives/1/getpaperdetails",
          "journals/v1/",
          "paperid=" + paperid
        )
        .then(
          (res) => {
            this.paperDetails = res.data.paperdetails;
          },
          (err) => {
            console.log(err);
          }
        );
    },
    sortedIssues: function (val) {
      return val.sort().reverse();
    },
    sortData: function () {
      this.years = Object.keys(this.archives).sort().reverse();
    },
    viewIssueData: function (year, volume, issue) {
      this.getPapersList(year, volume, issue);
      this.cYear = year;
      this.cVolume = volume;
      this.cIssue = issue;
      this.listVisible = true;
      this.$router.push({
        path: "archives",
        query: { year: year, volume: volume, issue: issue },
      });
    },
    viewPaperDetails: function (paperid) {
      this.getPaperDetails(paperid);
      this.paperPage = true;
      this.$router.push({ path: "archives", query: { paperid: paperid } });
    },
    downloadPDF: function (paperURL) {
      window.open(paperURL, "_blank");
    },
    googleSearch(url) {
      window.open(url, "_blank");
    },
  },
  watch: {
    $route: function () {
      console.log("here");
      if (
        !this.paperPage &&
        this.listVisible &&
        this.cYear == this.$route.query.year &&
        this.cVolume == this.$route.query.volume &&
        this.cIssue == this.$route.query.issue
      ) {
        this.listVisible = true;
      } else {
        this.listVisible = false;
      }

      if ("paperid" in this.$route.query) {
        this.viewPaperDetails(this.$route.query.paperid);
      } else {
        this.paperPage = false;
        this.getArchivesData();
      }
    },
    // Watcher to update document title based on paperDetails.paper_title
    'paperDetails.paper_title': function (newTitle) {
      document.title = newTitle || "Default Paper Title"; // Set default if title is missing
    }
  },
  mounted: function () {
    if ("paperid" in this.$route.query) {
      this.viewPaperDetails(this.$route.query.paperid);
    } else {
      this.listVisible = false;
      this.paperPage = false;
      this.getArchivesData();
      console.log("here");
    }
  },
};
</script> -->


<!-- <script>
export default {
  data() {
    return {
      archives: {},
      years: [],
      papersList: [],
      listVisible: false,
      paperPage: false,
      cYear: "",
      cVolume: "",
      cIssue: "",
      paperDetails: {},
      ManuScriptUrl: '',
      CopyRightFormUrl: '',
      images: [
        { img: require('../../../assets/Images/one.jpeg') },
        { img: require('../../../assets/Images/two.png') },
        { img: require('../../../assets/Images/three.png') },
        { img: require('../../../assets/Images/four.png') },
        { img: require('../../../assets/Images/five.png') },
        { img: require('../../../assets/Images/six.jpg') },
        { img: require('../../../assets/Images/seven.jpg') },
        { img: require('../../../assets/Images/eight.jpeg') },
        { img: require('../../../assets/Images/nine.jpeg') },
        { img: require('../../../assets/Images/ten.png') },
        { img: require('../../../assets/Images/eleven.png') },
        { img: require('../../../assets/Images/twele.jpg') },
        { img: require('../../../assets/Images/thirteen.jpg') },
        { img: require('../../../assets/Images/nineteen.png') },
        { img: require('../../../assets/Images/fourteen.png') },
        { img: require('../../../assets/Images/fifteen.jpg') },
        { img: require('../../../assets/Images/sixteen.jpg') },
        { img: require('../../../assets/Images/seventeen.jpeg') },
        { img: require('../../../assets/Images/eighteen.jpg') },
      ],
    };
  },
  methods: {
    getArchivesData() {
      this.api.getDataModule("archives/1/getarchives", "journals/v1/").then(
        (res) => {
          console.log(res.data);
          this.archives = res.data.archives;
          this.sortData();
        },
        (err) => {
          console.log(err);
        }
      );
    },
    getManuScript(getUrl) {
      window.open(getUrl, "_blank");
    },
    getCopyRightForm(getForm) {
      window.open(getForm, "_blank");
    },
    getPapersList(year, volume, issue) {
      this.api
        .getDataModulePagination(
          "archives/1/getpaperslist",
          "journals/v1/",
          "year=" + year + "&volume=" + volume + "&issue=" + issue
        )
        .then(
          (res) => {
            this.papersList = res.data.papersList;
          },
          (err) => {
            console.log(err);
          }
        );
    },
    getPaperDetails(paperid) {
      this.api
        .getDataModulePagination(
          "archives/1/getpaperdetails",
          "journals/v1/",
          "paperid=" + paperid
        )
        .then(
          (res) => {
            this.paperDetails = res.data.paperdetails;
          },
          (err) => {
            console.log(err);
          }
        );
    },
    sortedIssues(val) {
      return val.sort().reverse();
    },
    sortData() {
      this.years = Object.keys(this.archives).sort().reverse();
    },
    viewIssueData(year, volume, issue) {
      this.getPapersList(year, volume, issue);
      this.cYear = year;
      this.cVolume = volume;
      this.cIssue = issue;
      this.listVisible = true;
      this.$router.push({
        path: "archives",
        query: { year: year, volume: volume, issue: issue },
      });
    },
    viewPaperDetails(paperid) {
      this.getPaperDetails(paperid);
      this.paperPage = true;
      this.$router.push({ path: "archives", query: { paperid: paperid } });
    },
    downloadPDF(paperURL) {
      window.open(paperURL, "_blank");
    },
    googleSearch(url) {
      window.open(url, "_blank");
    },
    updateDocumentTitle() {
      if (this.paperPage) {
        document.title = this.paperDetails.paper_title || "Default Paper Title";
      } else if (this.cYear && this.cVolume && this.cIssue) {
        document.title = `${this.cYear} | Volume ${this.cVolume} - Issue ${this.cIssue}`;
      } else {
        document.title = "Default Title";
      }
    }
  },
  watch: {
    $route() {
      console.log("here");
      if (
        !this.paperPage &&
        this.listVisible &&
        this.cYear == this.$route.query.year &&
        this.cVolume == this.$route.query.volume &&
        this.cIssue == this.$route.query.issue
      ) {
        this.listVisible = true;
      } else {
        this.listVisible = false;
      }

      if ("paperid" in this.$route.query) {
        this.viewPaperDetails(this.$route.query.paperid);
      } else {
        this.paperPage = false;
        this.getArchivesData();
      }
      this.updateDocumentTitle();
    },
    'paperDetails.paper_title': function (newTitle) {
      this.updateDocumentTitle(); // Update title when paperDetails.paper_title changes
    },
    cYear(newYear) {
      this.updateDocumentTitle(); // Update title when cYear changes
    },
    cVolume(newVolume) {
      this.updateDocumentTitle(); // Update title when cVolume changes
    },
    cIssue(newIssue) {
      this.updateDocumentTitle(); // Update title when cIssue changes
    }
  },
  mounted() {
    if ("paperid" in this.$route.query) {
      this.viewPaperDetails(this.$route.query.paperid);
    } else {
      this.listVisible = false;
      this.paperPage = false;
      this.getArchivesData();
      console.log("here");
    }
    this.updateDocumentTitle(); // Initial title update
  },
};
</script> -->


<script>
export default {
  data() {
    return {
      archives: {},
      years: [],
      papersList: [],
      listVisible: false,
      paperPage: false,
      cYear: "",
      cVolume: "",
      cIssue: "",
      paperDetails: {},
      ManuScriptUrl: '',
      CopyRightFormUrl: '',
      images: [
        { img: require('../../../assets/Images/one.jpeg') },
        { img: require('../../../assets/Images/two.png') },
        { img: require('../../../assets/Images/three.png') },
        { img: require('../../../assets/Images/four.png') },
        { img: require('../../../assets/Images/five.png') },
        { img: require('../../../assets/Images/six.jpg') },
        { img: require('../../../assets/Images/seven.jpg') },
        { img: require('../../../assets/Images/eight.jpeg') },
        { img: require('../../../assets/Images/nine.jpeg') },
        { img: require('../../../assets/Images/ten.png') },
        { img: require('../../../assets/Images/eleven.png') },
        { img: require('../../../assets/Images/twele.jpg') },
        { img: require('../../../assets/Images/thirteen.jpg') },
        { img: require('../../../assets/Images/nineteen.png') },
        { img: require('../../../assets/Images/fourteen.png') },
        { img: require('../../../assets/Images/fifteen.jpg') },
        { img: require('../../../assets/Images/sixteen.jpg') },
        { img: require('../../../assets/Images/seventeen.jpeg') },
        { img: require('../../../assets/Images/eighteen.jpg') },
      ],
    };
  },
  methods: {
    getArchivesData() {
      this.api.getDataModule("archives/1/getarchives", "journals/v1/").then(
        (res) => {
          console.log(res.data);
          this.archives = res.data.archives;
          this.sortData();
        },
        (err) => {
          console.log(err);
        }
      );
    },
    getManuScript(getUrl) {
      window.open(getUrl, "_blank");
    },
    getCopyRightForm(getForm) {
      window.open(getForm, "_blank");
    },
    getPapersList(year, volume, issue) {
      this.api
        .getDataModulePagination(
          "archives/1/getpaperslist",
          "journals/v1/",
          "year=" + year + "&volume=" + volume + "&issue=" + issue
        )
        .then(
          (res) => {
            this.papersList = res.data.papersList;
          },
          (err) => {
            console.log(err);
          }
        );
    },
    getPaperDetails(paperid) {
      this.api
        .getDataModulePagination(
          "archives/1/getpaperdetails",
          "journals/v1/",
          "paperid=" + paperid
        )
        .then(
          (res) => {
            this.paperDetails = res.data.paperdetails;
          },
          (err) => {
            console.log(err);
          }
        );
    },
    sortedIssues(val) {
      return val.sort().reverse();
    },
    sortData() {
      this.years = Object.keys(this.archives).sort().reverse();
    },
    viewIssueData(year, volume, issue) {
      this.getPapersList(year, volume, issue);
      this.cYear = year;
      this.cVolume = volume;
      this.cIssue = issue;
      this.listVisible = true;
      this.$router.push({
        path: "archives",
        query: { year: year, volume: volume, issue: issue },
      });
    },
    viewPaperDetails(paperid) {
      this.getPaperDetails(paperid);
      this.paperPage = true;
      this.$router.push({ path: "archives", query: { paperid: paperid } });
    },
    downloadPDF(paperURL) {
      window.open(paperURL, "_blank");
    },
    googleSearch(url) {
      window.open(url, "_blank");
    },
    updateDocumentTitle() {
      if (this.paperPage) {
        document.title = this.paperDetails.paper_title || "Default Paper Title";
      } else if (this.cYear && this.cVolume && this.cIssue) {
        document.title = `${this.cYear} | Volume ${this.cVolume} - Issue ${this.cIssue}`;
      } else {
        document.title = "IJIRE- International Journal of Innovative Research in Engineering";
      }
    }
  },
  watch: {
    $route() {
      console.log("here");
      if (
        !this.paperPage &&
        this.listVisible &&
        this.cYear === this.$route.query.year &&
        this.cVolume === this.$route.query.volume &&
        this.cIssue === this.$route.query.issue
      ) {
        this.listVisible = true;
      } else {
        this.listVisible = false;
      }

      if ("paperid" in this.$route.query) {
        this.viewPaperDetails(this.$route.query.paperid);
      } else {
        this.paperPage = false;
        this.getArchivesData();
      }
      this.updateDocumentTitle();
    },
    'paperDetails.paper_title': function () {
      this.updateDocumentTitle(); // Update title when paperDetails.paper_title changes
    },
    cYear() {
      this.updateDocumentTitle(); // Update title when cYear changes
    },
    cVolume() {
      this.updateDocumentTitle(); // Update title when cVolume changes
    },
    cIssue() {
      this.updateDocumentTitle(); // Update title when cIssue changes
    }
  },
  mounted() {
    if ("paperid" in this.$route.query) {
      this.viewPaperDetails(this.$route.query.paperid);
    } else {
      this.listVisible = false;
      this.paperPage = false;
      this.getArchivesData();
      console.log("here");
    }
    this.updateDocumentTitle(); // Initial title update
  },
};
</script>

<style scoped>
.rowSize{
  width: 97%;
}
.issueButton {
  cursor: pointer;
}
.box {
  background: #0000ff;
  border-radius: 8px;
}
.box p {
  color: #fff;

  text-align: center;
  padding: 6px;
}

.btn-outline-primary {
  color: #0A87CF;
  border-color: #0A87CF;
}

.btn-outline-primary:hover {
  color: #fff;
  border-color: #0A87CF;
  background: #0A87CF;
}

.databox {
  background-color: #0A87CF;
  border-radius: 5px;
  cursor: pointer;
}
.databox p {
  color: #fff;
  padding: 10px;
}
.paperBox {
  /* border: 2px solid #EEEDE7; */
  
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.paperHeading{
display: flex;
    justify-content: center;
}
p {
  margin-bottom: 5px;
  text-align: left;
}
h5 {
  margin-bottom: 5px;
  margin-top: 5px;
}
h6 {
  margin-bottom: 5px;
  margin-top: 5px;
}
.abstract-details {
  white-space: pre-wrap;
}
@media screen and (max-width: 991px) {
  .btn-sm {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 512px) {
  .btn-sm {
    font-size: 10px !important;
  }
}
@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 0.5s infinite;
  background-color: #74a9da;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  padding: 5px;
}

.shadow-effect {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 8px;
  border-radius: 5px;
}
.image-container {
  display: flex;
  flex-wrap: wrap;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 2px;
}

.image-wrapper {
  flex: 0 0 50%;
  /* Each image takes up 50% of the row, so 2 images per row */
  box-sizing: border-box;
  /* Ensures padding and border are included in the width */
  padding: 5px;
  /* Adjust padding as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-wrapper img {
  max-width: 100%;
  /* Ensures images are responsive */
  height: auto;
  /* Maintains aspect ratio */
  margin-bottom: 10px;
}
@media screen and (max-width: 1000px) {
  .left-side-container{
    display: none;
  }
  .right-side-container{
    display: none;
  }
}
</style>

